<template>
    <Form @submit="handleSubmit" @invalid-submit="handleFormInvalidSubmit" v-slot="{ errors }" class="checkout-setting-form">
        <div class="content_area">
            <div class="content_wpr">
                <div class="section_content w-100">
                    <div class="section_header">
                        <h2>Emails</h2>
                        <div class="tutorial_btn" @click="tutorialVideo"><label>Tutorials</label><img src="@/assets/images/play.svg"></div>
                        <!-- <button type="button" class="video_btn setting-video-btn" @click="tutorialVideo"><i class="fas fa-play"></i></button> -->
                    </div>
                    <div class="mt-1 p-0 border-0">
                        <div class="setting_wpr">
                            <ul class="mail_types">
                                <li :class="{ 'show': tab == 1 }">
                                    <h4 @click="tab = (tab == 1 ? 0 : 1)">Initial Order Reciept<i class="fas fa-chevron-down"></i></h4>
                                    <div class="accordion_area">
                                        <div class="mt-5 mb-5">
                                            <p class="note mb-4">Customize the system email sent to customer when any new order is placed</p>
                                            <email-component v-if="isMounted" v-model="orderReciept" :errors="errors" :handle-default-email="handleOrderRecieptDefaultEmail" />
                                        </div>
                                    </div>
                                </li>
                                <li :class="{ 'show': tab == 2 }">
                                    <h4  @click="tab = (tab == 2 ? 0 : 2)">Order Refunded<i class="fas fa-chevron-down"></i></h4>
                                    <div class="accordion_area">
                                        <div class="mt-5 mb-5">
                                            <p class="note mb-4">Customize the system email sent to customer when any refund is issued</p>
                                            <email-component v-if="isMounted" v-model="orderRefunded" :errors="errors" :handle-default-email="handleOrderRefundedDefaultEmail" />
                                        </div>
                                    </div>
                                </li>
                                <li :class="{ 'show': tab == 3 }">
                                    <h4  @click="tab = (tab == 3 ? 0 : 3)">Update Payment Details<i class="fas fa-chevron-down"></i></h4>
                                    <div class="accordion_area">
                                        <div class="mt-5 mb-5">
                                            <p class="note mb-4">Customize the system email sent to customer when payment method needs updating</p>
                                            <email-component v-if="isMounted" v-model="updatePayment" :errors="errors" :handle-default-email="handleUpdatePaymentDefaultEmail" />
                                        </div>
                                    </div>
                                </li>
                                <li :class="{ 'show': tab == 4 }">
                                    <h4  @click="tab = (tab == 4 ? 0 : 4)">Subscription Cancelled<i class="fas fa-chevron-down"></i></h4>
                                    <div class="accordion_area">
                                        <div class="mt-5 mb-5">
                                            <p class="note mb-4">Customize the system email sent to customer when subscription is cancelled</p>
                                            <email-component v-if="isMounted" v-model="subscriptionCancelled" :errors="errors" :handle-default-email="handleSubscriptionCancelledDefaultEmail" />
                                        </div>
                                    </div>
                                </li>
                                <li :class="{ 'show': tab == 5 }">
                                    <h4  @click="tab = (tab == 5 ? 0 : 5)">Subscription Refunded<i class="fas fa-chevron-down"></i></h4>
                                    <div class="accordion_area">
                                        <div class="mt-5 mb-5">
                                            <p class="note mb-4">Customize the system email sent to customer when any subscription is refunded</p>
                                            <email-component v-if="isMounted" v-model="subscriptionRefunded" :errors="errors" :handle-default-email="handleSubscriptionRefundedDefaultEmail" />
                                        </div>
                                    </div>
                                </li>
                                <li :class="{ 'show': tab == 6 }">
                                    <h4  @click="tab = (tab == 6 ? 0 : 6)">Subscription Charged<i class="fas fa-chevron-down"></i></h4>
                                    <div class="accordion_area">
                                        <div class="mt-5 mb-5">
                                            <p class="note mb-4">Customize the system email sent to customer when any subscription rebill is successfully charged</p>
                                            <email-component v-if="isMounted" v-model="subscriptionCharged" :errors="errors" :handle-default-email="handleSubscriptionChargedDefaultEmail" />
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="action_wpr">
            <button type="button" class="btn cancel_btn" @click="$parent.$parent.closeModal()">Cancel</button>
            <button :disabled="loader" type="submit" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="loader"></i> {{ loader ? 'Saving' : 'Save' }}</button>
        </div>
    </Form>
</template>

<script>
    import { Form } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    export default {
        name: 'Transaction Email Setting',

        data () {
            return {
                tab: 1,
                orderReciept: {
                    defaultHandler: true,
                    subject: '',
                    email: '',
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                },
                orderRefunded: {
                    defaultHandler: true,
                    subject: '',
                    email: '',
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                },
                updatePayment: {
                    defaultHandler: true,
                    subject: '',
                    email: '',
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                },
                subscriptionCancelled: {
                    defaultHandler: true,
                    subject: '',
                    email: '',
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                },
                subscriptionRefunded: {
                    defaultHandler: true,
                    subject: '',
                    email: '',
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                },
                subscriptionCharged: {
                    defaultHandler: true,
                    subject: '',
                    email: '',
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                },
                isMounted: false,
                // selectedEmailSetting: {},
            }
        },

        props: {
            updateTransactionEmail: {
                type: Function,
                default: () => {}
            },
            tutorialVideo : Function,
        },

        components: {
            Form,
        },

        watch: {
            transactionEmailSettings (setting) {
                const vm = this;

                vm.resetForm();
            },

            tab (tab) {
                const vm = this;

                if (tab == 1) {
                    vm.updateTransactionEmail(vm.orderReciept);
                } else if (tab == 2) {
                    vm.updateTransactionEmail(vm.orderRefunded);
                } else if (tab == 3) {
                    vm.updateTransactionEmail(vm.updatePayment);
                } else if (tab == 4) {
                    vm.updateTransactionEmail(vm.subscriptionCancelled);
                } else if (tab == 5) {
                    vm.updateTransactionEmail(vm.subscriptionRefunded);
                } else if (tab == 6) {
                    vm.updateTransactionEmail(vm.subscriptionCharged);
                }
            },

            orderReciept: {
                handler (orderReciept) {
                    const vm = this;

                    if (vm.tab == 1) {
                        vm.updateTransactionEmail(orderReciept);
                    }
                },
                deep: true,
            },

            orderRefunded: {
                handler (orderRefunded) {
                    const vm = this;

                    if (vm.tab == 2) {
                        vm.updateTransactionEmail(orderRefunded);
                    }
                },
                deep: true,
            },

            updatePayment: {
                handler (updatePayment) {
                    const vm = this;

                    if (vm.tab == 3) {
                        vm.updateTransactionEmail(updatePayment);
                    }
                },
                deep: true,
            },

            subscriptionCancelled: {
                handler (subscriptionCancelled) {
                    const vm = this;

                    if (vm.tab == 4) {
                        vm.updateTransactionEmail(subscriptionCancelled);
                    }
                },
                deep: true,
            },

            subscriptionRefunded: {
                handler (subscriptionRefunded) {
                    const vm = this;

                    if (vm.tab == 5) {
                        vm.updateTransactionEmail(subscriptionRefunded);
                    }
                },
                deep: true,
            },

            subscriptionCharged: {
                handler (subscriptionCharged) {
                    const vm = this;

                    if (vm.tab == 6) {
                        vm.updateTransactionEmail(subscriptionCharged);
                    }
                },
                deep: true,
            },
        },

        computed: mapState({
            transactionEmailSettings: state => state.checkoutModule.transactionEmailSettings,
            emailSettingLoader: state => state.checkoutModule.emailSettingLoader,
            loader: state => state.checkoutModule.updateEmailSettingLoader,
        }),

        mounted () {
            const vm = this;

            if (!Object.keys(vm.transactionEmailSettings).length) {
                vm.getTransactionEmailSettings({type: 2});
            }

            vm.resetForm();
        },

        methods: {
            ...mapActions({
                getTransactionEmailSettings: 'checkoutModule/getEmailSettings',
                updateEmailSettings: 'checkoutModule/updateEmailSettings'
            }),

            resetForm () {
                const vm = this;

                if (vm.transactionEmailSettings && vm.transactionEmailSettings.length) {
                    const emailSettings = JSON.parse(JSON.stringify(vm.transactionEmailSettings));

                    emailSettings.forEach(({ step }, index) => {
                        if (step == 1) {
                            vm.orderReciept = emailSettings[index];
                            vm.orderReciept.defaultHandler = true;
                        } else if (step == 2) {
                            vm.orderRefunded = emailSettings[index];
                            vm.orderRefunded.defaultHandler = true;
                        } else if (step == 3) {
                            vm.updatePayment = emailSettings[index];
                            vm.updatePayment.defaultHandler = true;
                        } else if (step == 4) {
                            vm.subscriptionCancelled = emailSettings[index];
                            vm.subscriptionCancelled.defaultHandler = true;
                        } else if (step == 5) {
                            vm.subscriptionRefunded = emailSettings[index];
                            vm.subscriptionRefunded.defaultHandler = true;
                        } else if (step == 6) {
                            vm.subscriptionCharged = emailSettings[index];
                            vm.subscriptionCharged.defaultHandler = true;
                        }
                    });

                    vm.isMounted = true;
                }
            },

            handleSubmit () {
                const vm = this;

                const form = [ vm.orderReciept, vm.orderRefunded, vm.updatePayment, vm.subscriptionCancelled, vm.subscriptionRefunded, vm.subscriptionCharged ];

                vm.updateEmailSettings(form).then((result) => {
                    if (result) {
                        vm.getTransactionEmailSettings({type: 2});
                    }
                });
            },

            handleOrderRecieptDefaultEmail () {
                const vm = this;
                vm.orderReciept.subject = 'Thank You For Your Order';
                vm.orderReciept.email   =  `<p>Hi {{first_name}},</p>
                                            <p>Congratulations on your recent purchase from {{company_name}}!</p>
                                            <p>You should have received an email with access to your purchased products. If you failed to receive these emails, don't worry!</p><p>Help is just a click away at {{support_email}}.</p><p>If you have any questions, please reference order id: {{order_id}}</p><p>Your date of purchase was: {{date_of_purchase}}</p><p>Please do not reply to this email. It is not a monitored address.</p>
                                            <p>Cheers!</p>
                                            <p>{{company_owner_first_name}}</p>
                                            <p>{{company_name}}</p>`;
            },

            handleOrderRefundedDefaultEmail () {
                const vm = this;

                vm.orderRefunded.subject = 'Refund issued';
                vm.orderRefunded.email   =  `<p>Hi {{first_name}},</p>
                                            <p>We have issued a refund of {{XX}} against order ID: {{order_id}}, and you should see it in your bank account within 3-7 business days depending, on your bank policies.</p>
                                            <p>Please do not reply to this email. It is not a monitored address.</p>
                                            <p>Cheers!</p>
                                            <p>{{company_owner_first_name}}</p>
                                            <p>{{company_name}}</p>`;
            },

            handleUpdatePaymentDefaultEmail () {
                const vm = this;

                vm.updatePayment.subject = 'We have a problem with your payment.';
                vm.updatePayment.email   = `<p>Hi {{first_name}},</p>
                                            <p>Your payment source on file has either expired or is expiring soon. Please use the link below or contact support at {{support_email}} to update your payment details so that your program or product access is not interrupted.</p>
                                            <p>{{link}}</p><p>Please do not reply to this email. It is not a monitored address.</p>
                                            <p>Cheers!</p>
                                            <p>{{company_owner_first_name}}</p>
                                            <p>{{company_name}}</p>`;
            },

            handleSubscriptionCancelledDefaultEmail () {
                const vm = this;

                vm.subscriptionCancelled.subject = 'Your subscription to {{product_name}} has been cancelled.';
                vm.subscriptionCancelled.email   = `<p>Hi {{first_name}},</p>
                                                    <p>Your subscription to {{product_name}} has been cancelled. Depending on our terms and conditions, you may no longer have access to your product.</p>
                                                    <p>If you feel this-was-done in error, please contact support at {{support_email}} immediately. Use the subject line: ERROR.</p>
                                                    <p>Please do not reply to this email. It is not a monitored address.</p>
                                                    <p>Cheers!</p>
                                                    <p>{{company_owner_first_name}}</p>
                                                    <p>{{company_name}}</p>`;

            },

            handleSubscriptionRefundedDefaultEmail () {
                const vm = this;

                vm.subscriptionRefunded.subject = 'Refund Issued';
                vm.subscriptionRefunded.email   = `<p>Hi {{first_name}},</p>
                                                    <p>We have issued a refund of {{XX}} against subscription ID: {{subscription_id}}, and you should see it in your bank account within 3-7 business days depending, on your bank policies.</p>
                                                    <p>Please do not reply to this email. It is not a monitored address.</p>
                                                    <p>Cheers!</p>
                                                    <p>{{company_owner_first_name}}</p>
                                                    <p>{{company_name}}</p>`;
            },

            handleSubscriptionChargedDefaultEmail () {
                const vm = this;

                vm.subscriptionCharged.subject = 'Subscription successfully charged'
                vm.subscriptionCharged.email   = `<p>Hi {{first_name}},</p>
                                                    <p>We have successfully processed a subscription payment of {{XX}} for your membership in {{product_name}} with a subscription ID of: {{subscription_id}}. If you feel this was in error, please email support at {{support_email}}.</p>
                                                    <p>Please do not reply to this email. It is not a monitored address.</p>
                                                    <p>Cheers!</p>
                                                    <p>{{company_owner_first_name}}</p>
                                                    <p>{{company_name}}</p>`;
            },
        }
    }
</script>

<style scoped>
    .section_header .tutorial_btn{
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    .section_header .tutorial_btn label {
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #2f7eed;
        cursor: pointer;
        min-width: auto;
    }
    .section_header .tutorial_btn img {
        max-height: 18px;
        width: auto;
        margin-left: 5px;
    }
    .checkout-setting-form {
        display: flex;
        flex-direction: column;
        height: inherit;
    }

    .mail_types  {
        margin: 10px 0;
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    .mail_types li  {
        background: #fff;
        border-radius: 8px;
        box-shadow: 0 1px 10px rgba(0, 0, 0, 0.05);
        overflow: hidden;
    }

    .mail_types li h4  {
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        color: #121525;
        padding: 20px 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: move;
    }

    .mail_types li h4 i  {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid #e9e9e9;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        cursor: pointer;
        color: #7e7e7e;
        transition: all 0.3s ease-in-out;
    }

    .mail_types li.show h4 i  {
        transform: rotate(-180deg);
    }

    .mail_types li .accordion_area  {
        max-height: 0;
        padding: 0 30px;
        height: 100%;
        border-top: 1px solid #e9e9e9;
        overflow: hidden;
        transition: all 0.3s ease-in-out;
    }

    .mail_types li.show .accordion_area  {
        max-height: 1000vh;
        height: 100%;
        overflow-y: scroll;
    }

    .mail_types li.show .accordion_area::-webkit-scrollbar {
        display: none;
    }

    p.note {
        padding: 15px 20px;
        background: rgba(47, 126, 237, 0.05);
        border: 1px solid rgba(47, 126, 237, 0.1);
        border-radius: 5px;
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        color: #121525;
    }

    :deep(p.note p) {
        margin: 10px 0;
    }
    @media(max-width: 599px){
        .mail_types li h4  {
            font-size: 13px;
            line-height: 18px;
            padding: 15px 20px;
        }
        .mail_types li .accordion_area{
            padding: 0 15px;
        }
        p.note {
            padding: 10px 15px;
            font-size: 11px;
            line-height: 18px;
        }
    }
</style>
